import classNames from 'classnames';
import { Moment } from 'moment';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Label } from 'shared/components/elements/elements.components';
import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { DatePicker } from 'shared/components/date-picker/date-picker.component';
import { useFormControlAsDatePickerState } from 'app/hooks/forms/use-form-control-as-state.hook';
import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';

interface OwnProps {
  renderErrors?: (field: NavCarrierFormControl) => ReactNode;
  id: string;
  className?: string;
  label: ResourceKey | (() => ReactNode);
  field: NavCarrierFormControl;
  onChange?: (date: Moment) => any;
  onBlur?: () => any;
}

export const DateField = ({ field, label, id, renderErrors, className, onChange, onBlur }: OwnProps) => {
  const [value, innerOnChange, innerOnBlur] = useFormControlAsDatePickerState(field, onChange, onBlur);
  const { t } = useTranslation();

  return (
    <div className={classNames(className, 'form-group', { 'has-error': field.hasErrors() })}>
      <ErrorBoundary>
        <Label htmlFor={id}>
          {typeof label === 'function' ? label() : t(label)}
        </Label>
        <DatePicker
          id={id}
          selected={value}
          onChange={innerOnChange}
          onBlur={innerOnBlur}
        />
        {typeof renderErrors === 'function' && renderErrors(field)}
      </ErrorBoundary>
    </div>
  );
};
