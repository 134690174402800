import { FormEvent, useCallback, useEffect, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Moment } from 'moment';
import Popper from 'popper.js';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/elements/elements.components';
import { useResource } from 'app/hooks/store/use-resource.hook';
import { useLocaleValues } from 'shared/components/date-picker/hooks/use-locale-values.hook';
import { useOnDateChange } from 'shared/components/date-picker/hooks/use-on-date-change.hook';

import './date-picker.component.scss';

export interface Props {
  id?: string;
  locale?: string;
  selected: Moment;
  minDate?: Moment;
  maxDate?: Moment;
  disabled?: boolean;
  onChange: (date) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  hasClearButton?: boolean;
  datePickerRef?: (ref: ReactDatePicker) => any;
  popperPlacement?: Popper.Placement;
  popperProps?: object;
  wrapperClassName?: string;
}

// Workaround for default import issues
// @see https://github.com/Hacker0x01/react-datepicker/issues/1163
// @see https://github.com/angular/angular/issues/11402
// @note This may be able to be removed once ngtools is removed from our webpack config
// const ReactDatePicker = BaseDatePicker.default ? BaseDatePicker.default : BaseDatePicker;

export const DatePicker = ({ minDate, maxDate, disabled, selected, hasClearButton = true, id, ...props }: Props) => {
  const datePickerRef = useRef<ReactDatePicker>(null);
  const CLEAR = useResource('CLEAR');
  const { t } = useTranslation();

  useEffect(() => {
    if (props.datePickerRef) {
      props.datePickerRef(datePickerRef.current);
    }
  },
    [datePickerRef.current, props.datePickerRef]
  );

  const [placeholder, dateFormat] = useLocaleValues(props.locale);

  const toggleOpen = useCallback((e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (datePickerRef?.current) {
      datePickerRef.current.setOpen(!datePickerRef.current.isCalendarOpen());
    }
  }, [datePickerRef]);

  const [onChange, onChangeRaw] = useOnDateChange(props.onChange);

  return (
    <div className="date-picker-component">
      <div className="input-group full-width">
        <ReactDatePicker
          id={id}
          ref={datePickerRef}
          className="form-control"
          wrapperClassName={props.wrapperClassName}
          todayButton="Select Today"
          dateFormat={dateFormat}
          placeholderText={placeholder}
          onChange={onChange}
          onChangeRaw={onChangeRaw}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          selected={!!selected && selected?.isValid() ? selected?.toDate() : null}
          disabled={disabled}
          minDate={minDate?.toDate()}
          maxDate={maxDate?.toDate()}
          isClearable={hasClearButton}
          clearButtonTitle={CLEAR}
          showDisabledMonthNavigation
          disabledKeyboardNavigation
          popperPlacement={props.popperPlacement}
          popperProps={props.popperProps}
        />
        <span className="input-group-btn">
          <Button
            btnPrimary
            className="ns-icon ns-calendar"
            disabled={disabled}
            onClick={toggleOpen}
          >
            <span className="visually-hidden">{t('EXPAND_CALENDAR')}</span>
          </Button>
        </span>
      </div>
    </div>
  );
};
